<template>
  <div>
    <v-breadcrumbs v-if="!isEmbedded" :items="breadcrumbItems"/>

    <v-card  v-if="event">
      <EventHeader v-if="!isEmbedded" :event="event"/>

      <v-card-text>
        <FeedList :feed-items="feedItems" />
      </v-card-text>

    </v-card>
  </div>      
</template>

<script>
import { mapGetters } from "vuex";
import { EventBus } from '@/plugins/eventbus.js';
import eventService from "@/services/eventService";
import EventHeader from '@/components/EventHeader.vue';
import FeedList from '@/components/FeedList.vue';
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "EventFeed",
  components: {
    EventHeader,
    FeedList,
  },
  props: {
  },
  data() {
    return {
      tenant: tenant,
      event: null,
      feedItems: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getEvent(id) {
      var response = await eventService.get(id);
      this.event = response.data;
      await this.loadFeed();
    },

    async loadFeed() {
      this.feedItems = (await eventService.getFeed(this.event.id)).data.data;
    },

    async getProfile() {
      if (this.user) {
        await this.getEvent(this.$route.params.id);
      }
    },


  },
  computed: {
    breadcrumbItems() {
      return !this.event ? [] : [
        { text: this.event.name, exact: true, to: { name: 'event', params: {id: this.event.id}} },
        { text: 'Feed', disabled: true },
      ];
    },
    isEmbedded() {
      return this.$route.query.view === 'embed' || this.$store.state.view == 'embed' || this.isEmbeddedInApp;
    },
    isEmbeddedInApp() {
      return this.$route.query.view === 'app' || this.$store.state.view == 'app';
    },
    ...mapGetters({
      user: "user"
    })
  },
};
</script>
<style lang="scss">
</style>

